body {
  padding-top: 3.5rem;
}

.rotate {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

#burger:after {
  display: none;
}

.link-button {
  user-select: text;
  color: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0px;
  padding: 0px;
}

#playIconInVideo {
  font-size: 400%;
  position: absolute;
  top: 44%;
  left: 48%;
  z-index: 1;
}

#playButtonInVideo {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  box-shadow: none;
  position: relative;
}

#SUBTITLE_ID_ELT {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 2;
}

#BIF_THUMBNAIL_ELT {
  position: absolute;
  bottom: 0;
  z-index: 3;
  color: white;
}

@keyframes fade {
  from {
    opacity: 0;
  }
}

.homecard{
  transition: .1s linear;
}

.homecard:hover{
  transform: scale(1.05);
  box-shadow: 4px 4px 4px lightgray;
}

.fader {
  animation: fade 1s infinite alternate;
}
